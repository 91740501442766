import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Statistic } from 'antd';
const { Countdown } = Statistic;

const Whisper = (props) => {
  const [deadline, setDeadline] = useState(0)
  useEffect(()=>{
    const date = Date.now() + props.auction.countdown_time * 1000;
    setDeadline(date)
  },[props.auction])
  // const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2;
  // 品牌数据
  const brandList = [
    {
      text:'全部',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_quanbu.png'
    },
    {
      text:'手机',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_phone.png'
    },
    {
      text:'笔记本',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_book.png'
    },
    {
      text:'平板',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_pingban.png'
    },
    {
      text:'摄影摄像',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_photo.png'
    },
    {
      text:'智能数码',
      img:'https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_watch.png'
    },
  ];
  // 专场数据
  const siteDate = ['https://resources.huanhuanhuishou.com/new_image/20210812/hhyp_pc_youping.png','https://resources.huanhuanhuishou.com/new_image/20210812/hhyp_pc_jianlou.png'];
  // 热门品牌
  const hotBrandData = ["https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_iphone.png","https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_huawei.png","https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_xiaomi.png","https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_honor.png","https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_vivo.png","https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_oppo.png"];
  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '26px',
    fontFamily: 'Source Han Sans CN, Source Han Sans C',
    fontWeight: 'bold',
    color: '#fff',
    marginTop: '3px'
  }

  const joinSecretShot = () => {
    props.joinSecretShot()
  }
  return (
    <div className={styles.whisper}>
      <div className={styles.title}>暗拍专场</div>
      {/* <div className={styles.tag}>货源稳定，品类齐全，每天19:00准时开场</div>
      <div className={styles.show} onClick={joinSecretShot}>
        {
          props.auction && props.auction.active_state == 1 ? (
          <div className={styles.time}>
            <div className={styles.text}>距离结束</div>
            <Countdown value={deadline} onFinish={onFinish} valueStyle={countdownStyle} />
          </div>
          ):(
            <div className={styles.over}>
              <div>尚未开始</div>
            </div>
          )
        }
        
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.brand}>
              {brandList.map((item,index)=>{
                return (
                  <div className={styles.brand_item} key={index}>
                    <div className={styles.text}>{item.text}</div>
                    <img src={item.img} style={index !== 0 ? {width:'44px',height:'44px'} : {width:'30px',height:'30px'}} />
                  </div>
                )
              })}
            </div>
            <div className={styles.site}>
              {siteDate.map((item,index)=>{
                return (
                  <img className={styles.site_item} src={item} key={index} />
                )
              })}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>热门品牌</div>
            <div className={styles.hot_brand}>
              {hotBrandData.map((item,index)=>{
                return (
                  <img className={styles.hot_brand_item} src={item} key={index} />
                )
              })}
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.dark_shooting}>
        <div className={styles.shooting_mode}>
          <div className={styles.left_text}>
            <div className={styles.shooting_title}>优品好场</div>
            <div className={styles.shooting_ls}>最大货全，每场上新</div>
            <div className={styles.shooting_shot} onClick={joinSecretShot}>
              {props.auction && props.auction.active_state == 1 ? (
                <div className={styles.shooting_bnt}><Countdown value={deadline} onFinish={onFinish} valueStyle={countdownStyle} /></div>
              ) : (
                <div className={styles.over}>
                  <div>尚未开始</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.shooting_img} onClick={joinSecretShot}>
            <img alt='' src='https://resources.huanhuanhuishou.com/new_image/20241213/Slice-357.png' />
          </div>
        </div>
        <div className={styles.shooting_props}>
          <div className={styles.left_text}>
            <div className={styles.shooting_title}>捡漏专场</div>
            <div className={styles.shooting_ls}>全场最低，手快者得</div>
            <div className={styles.shooting_shot} onClick={joinSecretShot}>
              {props.auction && props.auction.active_state == 1 ? (
                <div className={styles.shooting_bnt}><Countdown value={deadline} onFinish={onFinish} valueStyle={countdownStyle} /></div>
              ) : (
                <div className={styles.over}>
                  <div>尚未开始</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.shooting_img} onClick={joinSecretShot}>
            <img alt='' src='https://resources.huanhuanhuishou.com/new_image/20241213/Slice357-1.png' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Whisper