import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Statistic } from 'antd';
import avatarSmall from '../../../../static/emptyIcon.png'
const { Countdown } = Statistic;

const Picker = (props) => {
  const [goodsList,setgoodsList] = useState()
  const [isVisible, setisVisible] = useState()
  useEffect(()=>{
    let list = props.goodsItem
    list.forEach(element => {
      element.img = element.list_img || element.goods_img
    });
    console.log(list)
    setgoodsList(list)
  },[props.goodsItem])
  // 商品数据

  useEffect(() => {
    setisVisible(props.isVisible)
  }, [props.isVisible])

  // 倒计时结束
  const onFinish = () => {
    console.log('倒计时结束');
  };
  // 倒计时数据样式
  const countdownStyle = {
    fontSize: '14px',
    fontFamily: 'PingFang SC',
    fontWeight: '400',
    color: '#fff'
  };
  const joinOnePrice = () => {
    props.joinOnePrice()
  }
  return (
    <div className={styles.picker} style={{ paddingBottom: isVisible ? '60px':'160px'}}>
      <div className={styles.title}>一口价专场</div>
      {/* <div className={styles.tag}>手机、平板、笔记本、智能数码、摄影摄像应有尽有</div> */}
      <div className={styles.content} onClick={joinOnePrice}>
        {goodsList && goodsList.length !==0 ? goodsList.map((item,index)=>{
          return (
            <div className={styles.goods_box} key={index}>
              <img alt='' className={styles.tag_img} src='https://resources.huanhuanhuishou.com/new_image/20241213/Slice363-2x.png' />
              <div className={styles.wrap_img}>
                {
                  item.img ? (<img alt='' className={styles.goods_img} src={item.img} />):(<img alt='' className={styles.goods_img} src={avatarSmall} />)
                }
              </div>
              <div className={styles.name}>
                <div className={styles.grade}>{item.grade}</div>
                <div className={styles.text}>{item.goods_name}</div>
              </div>
              <div className={styles.sku_desc}>
                {item.sku_desc}
              </div>
              <div className={styles.price}>
                <div>一口价</div>
                <div className={styles.icon_price}><span>&yen;</span>{item.price}</div>
              </div>
            </div>
          )
        }):null
        }
      </div>
    </div>
  )
};

export default Picker