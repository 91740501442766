import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import * as api from "../../../../api/index"; // 引入api
const Footer = (props) => {
  const [num, setNum] = useState(0);
  const [leftData, setLeftData] = useState([]);
  useEffect(() => {
    getArticle();
  }, [num]);
  // 获取资讯数据
  const getArticle = () => {
    api.homeApi.article({ page: 1, limit: 6 }).then((res) => {
      if (res.code == 200) {
        let data = res.data.article;
        setLeftData(data.splice(0, 2));
      }
    });
  };
  const joinDetails = (item) => {
    props.joinDetails(item);
  };
  // 二维码数据
  const twoCode = [
    {
      img: "https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_gzh.png",
      text: "优品拍拍服务号",
    },
    {
      img: "https://resources.huanhuanhuishou.com/new_image/20210813/hhyp_xiazai.png",
      text: "下载优品拍拍APP",
    },
  ];
  return (
    <div className={styles.footer}>
      <img alt="" className={styles.suspension} src="https://resources.huanhuanhuishou.com/new_image/20241213/footer_brank.jpg" />
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.left}>
            <div className={styles.phone}>
              <img alt="" src="https://resources.huanhuanhuishou.com/new_image/20241213/Slice-125.png" />
              <div className={styles.num}>135 3764 0175</div>
              <img alt="" src="https://resources.huanhuanhuishou.com/new_image/20241213/Slice125-1.png" />
              <div className={styles.num}>0755-26402351</div>

            </div>
            <div className={styles.text}>服务邮箱：zy@hhyp58.com</div>
            <div className={styles.text}>服务时间：周一至周日 09:00-22:30</div>
            <div className={styles.text}>
              公司地址：深圳市南山区粤海街道深圳湾科技生态园12栋5楼
            </div>
          </div>
          <div className={styles.right}>
            {twoCode.map((item, index) => {
              return (
                <div className={styles.code_box} key={index}>
                  <img src={item.img} alt="" />
                  <div className={styles.text}>{item.text}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.copyright}>
          {/* <div>
            <span
              onClick={joinDetails.bind(this, leftData[0])}
              className={styles.about_us}>
              关于我们
            </span>
          </div> */}
          <div className={styles.about_address}>
            <div className={styles.about_address_1}>
              网站备案/许可证号：
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                className={styles.color_active}
                rel="noreferrer">
                粤ICP备20060093号
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
